<template>
  <Page v-if="currentUser"
      :pageTitle="`Hello ${currentUser.name}`"
      customTitleClass="is-3 has-text-weight-bold mt-5 mb-1"
      isFluid
      :hasRightBar="false"
      class="is-dashboard">

      <!-- Row -->
      <Columns customClass="mb-6 is-multiline">
        <Column customClass="is-6 is-two-thirds-desktop is-three-quarters-fullhd">
          <task-progress />
        </Column>

        <Column customClass="">

          <div class="block">
            <widget-calendar />
          </div>

          <div class="block">
            <!-- <widget-milestones /> -->
            <widget-activity />
          </div>
        </Column>

      </Columns>
      <!-- Row -->

    <!-- <template #rightbar> -->
    <!-- </template> -->

    <template #subtitle>
      <h6>Hope you have a great day!</h6>
    </template>

  </Page>
</template>

<script>
import { defineAsyncComponent, onMounted , ref} from "vue";
import { useUser } from "@/use/useUser";

export default {
  components: {
    Page: defineAsyncComponent(() => import('@/components/layout/Page')),
    Columns: defineAsyncComponent(() => import('@/components/ds/Columns')),
    Column: defineAsyncComponent(() => import('@/components/ds/Column')),
    // WidgetTime: defineAsyncComponent(() => import('@/components/widgets/localTime')),
    TaskProgress: defineAsyncComponent(() => import('@/components/widgets/taskProgress')),
    WidgetCalendar: defineAsyncComponent(() => import('@/components/widgets/calendar')),
    WidgetActivity: defineAsyncComponent(() => import('@/components/widgets/activity')),
    // WidgetMilestones: defineAsyncComponent(() => import('@/components/widgets/milestones'))
  },
  setup() {
   const {currentUser} = useUser()
   const calendar = ref(null)

    onMounted(() => {
      // calendar.value.goToday()
    })

    return {
      currentUser,
      calendar
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
